<div *ngIf="!isMobileView; else mobile">
    <div (window:resize)="onResize($event)" class="nav-bg">
        <div class="nav-wrapper">
            <button id="portfolio" class="nav-item btn nav-font selected" (click)="onNavSelect('portfolio')">
                Portfolio
            </button>
            <button id="commission" class="nav-item btn nav-font " (click)="onNavSelect('commission')">
                Commission
            </button>
            <button id="about" class="nav-item btn nav-font " (click)="onNavSelect('about')">
                About
            </button>
        <!--<button class="nav-item btn nav-font " (click)="onNavSelect('contact')">
                Contact
            </button>--> 
            <a class="nav-item btn nav-font " href="https://www.instagram.com/ihasz_creations/" target="_blank">
                Instagram
            </a>
        </div>
    </div>
    <div class="page-indicator "></div>
</div>
  
<ng-template #mobile>
    <div (window:resize)="onResize($event)" class="nav-bg">
        <mat-expansion-panel hideToggle=true class="menu">
            <mat-expansion-panel-header>
            <span class="menu-title">
                Menu
            </span>
            </mat-expansion-panel-header>
            <div style="display: flex; flex-direction: column;">
                <button class="nav-item btn nav-font item-block-bg" (click)="onNavSelect('portfolio')">
                    Portfolio
                </button>
                <button class="nav-item btn nav-font item-block-bg" (click)="onNavSelect('commission')">
                    Commission
                </button>
                <button class="nav-item btn nav-font item-block-bg" (click)="onNavSelect('about')">
                    About
                </button>
            <!--<button class="nav-item btn nav-font " (click)="onNavSelect('contact')">
                    Contact
                </button>--> 
                <a class="nav-item btn nav-font item-block-bg" href="https://www.instagram.com/ihasz_creations/">
                    Instagram
                </a>
                
            </div>
        </mat-expansion-panel>
    </div>
</ng-template>
