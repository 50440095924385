import { Component, OnInit } from '@angular/core';
import { AboutService } from '../services/about.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  bioImage;
  bioText1;
  bioText2;
  bioText3;

  constructor( private aboutService: AboutService) { }

  ngOnInit(): void {
    this.aboutService.aboutInfo().subscribe( infoList => {
      infoList.forEach(info => {
      this.bioImage = info.get('imageUrl');
      this.bioText1 = info.get('bioText1');
      this.bioText2 = info.get('bioText2');
      this.bioText3 = info.get('bioText3');
      });
    });
  }

}
