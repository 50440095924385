import {Component, OnInit, Input} from '@angular/core';
import { GalleryService } from '../services/gallery.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit{
  
  artList = [];
  @Input() focusedImage : any;


  constructor( private galleryService: GalleryService) { }

  ngOnInit(): void {  
    this.galleryService.getGallery().subscribe(docList => {
      let unsortedList = [];
      docList.forEach(doc => {
        let art = {
          id: doc.id,
          imageUrl: doc.get("imageUrl"),
          displayName: doc.get('displayName'),
          displayMedium: doc.get('displayMedium'),
          displaySize: doc.get('displaySize'),
          orderId: doc.get('orderId')
        };
        unsortedList.push(art);
      });
      this.artList = unsortedList.sort((a1, a2) => a1.orderId - a2.orderId);

      this.focusedImage = this.artList[0];
    });

  }

  setFocusedImage(image) {
    this.focusedImage = image;
  }

}
