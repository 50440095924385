import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  @Output() selectedNav: EventEmitter<any> = new EventEmitter<any>();

  isMobileView = false;

  constructor() { }

  ngOnInit(): void {
    window.innerWidth <= 732 ? this.isMobileView = true : this.isMobileView = false;
  }

  onResize(event) {
    event.target.innerWidth <= 732 ? this.isMobileView = true : this.isMobileView = false;
  }

  onNavSelect(navName) {
    this.selectedNav.emit(navName);
    document.querySelectorAll('.nav-item').forEach(navItem => {
      navItem.id == navName ? navItem.classList.add('selected') : navItem.classList.remove('selected');
    });
  }
}
