import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryService } from './services/gallery.service';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ImageListComponent } from './gallery/image-list/image-list.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { GalleryFocusComponent } from './gallery/gallery-focus/gallery-focus.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AboutComponent } from './about/about.component';
import { CommissionComponent } from './commission/commission.component';
import { ContactComponent } from './contact/contact.component';

const firebaseConfig = {
  apiKey: "AIzaSyAcO5261QVjzSllSmII07UnNY8ripQkaB8",
  authDomain: "briana-ihasz.firebaseapp.com",
  databaseURL: "https://briana-ihasz.firebaseio.com",
  projectId: "briana-ihasz",
  storageBucket: "briana-ihasz.appspot.com",
  messagingSenderId: "239120130289",
  appId: "1:239120130289:web:d9c0cc10de23c3bfd68950",
  measurementId: "G-EQSXRECYV8"
};

@NgModule({
  declarations: [
    AppComponent,
    GalleryComponent,
    NavBarComponent,
    ImageListComponent,
    GalleryFocusComponent,
    AboutComponent,
    CommissionComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule,
    NgbModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  providers: [
    GalleryService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
