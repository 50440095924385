
<div id='focus' class="focused-image"
(click)="toggleInfo()"
(mouseover)="showInfo()" 
(mouseout)="hideInfo()"
></div>


<div class="wrapper" (mouseover)="showInfo()" (mouseout)="hideInfo()">
    <div class="info-card">
        <div class="col">
            <div class="image-info font-weight-bold" style="text-align: left;" >{{focusedImage.displayName}}</div>
        </div>
        <div class="col-5">
        <div class="image-info font-weight-bold" style="text-align: center;">{{focusedImage.displayMedium}}</div>
        </div>
        <div class="col">
        <div class="image-info font-weight-bold" style="text-align: right;">{{focusedImage.displaySize}}</div>
        </div>
    </div>
</div>






