import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class AboutService {

  constructor( private firestore: AngularFirestore ) {}

  aboutInfo() {
    return this.firestore.collection('About').get();
  }

}

