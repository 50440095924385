<div class ="bio-container" *ngIf="bioImage">

    <img class ="bio-image" src={{bioImage}}>
    <div class="bio-box textPrimary">
        <div class="card">
            <div class="card-header bio-title" style="background-color: #CCFCF1;">
                Briana Ihasz
            </div>
            <div class="card-body bio-text">
                <p>{{bioText1}}</p>
                <p>{{bioText2}}</p>
                <p>{{bioText3}}</p>
            </div>
        </div>
        <div class="card mt-3">
            <div class="card-header bio-title" style="background-color: #CCFCF1;">
                Contact Info
            </div>
            <div class="card-body bio-text row">
                <span class='col' tyle="text-align: left" >brianaihasz@outlook.com</span>
                <span class='col' style="text-align: center">(385)207-8142</span>
            </div>
        </div>
    </div>

   
</div>

<div class="mt-3 credit-box textPrimary" *ngIf="bioImage">
  
    <div>
        <span class='col' tyle="text-align: left" >This site was made with love by</span>
        <a href="https://github.com/LIIhasz" target="blank" style="margin-left: -8px;" >Liam Ihasz</a>
    </div>
 
</div>
