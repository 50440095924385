import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-focus',
  templateUrl: './gallery-focus.component.html',
  styleUrls: ['./gallery-focus.component.scss']
})
export class GalleryFocusComponent implements OnInit, OnChanges{
  @Input() focusedImage: any;
  
  constructor() { }

  ngOnInit(): void {
    if (this.focusedImage) {
      let focus = document.getElementById('focus');
      focus.style.backgroundImage =  'url("' + this.focusedImage.imageUrl + '")';
      //focus.style.height = (this.getImageWidth(this.focusedImage.imageUrl)  ).toString() + 'px';
    }
    
  }

  ngOnChanges() {
    let FImage = document.querySelector('.focused-image');
    document.querySelector('.info-card').classList.remove('solid');
    if (FImage.classList.contains('solid')) {
      FImage.classList.remove('solid');
      FImage.classList.add('clear');
    } else {
      FImage.classList.remove('clear');
      FImage.classList.add('solid');
    }
    let wait = setInterval(() => {
      FImage.classList.remove('clear');
      FImage.classList.add('solid');
      document.getElementById('focus').style.backgroundImage =  'url("' + this.focusedImage.imageUrl + '")';
      clearInterval(wait);
    }, 200);
    
    
  }

  toggleInfo() {
    let card = document.querySelector('.info-card');
    card.classList.contains('solid') ? card.classList.remove('solid') : card.classList.add('solid');
  }
  showInfo() {
    document.querySelector('.info-card').classList.add('solid');

  }
  hideInfo() {
    document.querySelector('.info-card').classList.remove('solid');
  }

  getImageWidth(url){   
    var img = new Image();
    img.src = url;

    return img.naturalWidth;
}

}
