import { Component, Input, OnInit, EventEmitter, Output, OnChanges} from '@angular/core';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit, OnChanges{

  @Input() imageList;
  @Output() imageChanged = new EventEmitter<any>();
  @Input() focusedImage;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    let container = document.querySelector('.image-list')
    document.querySelectorAll('.gallery-image').forEach(picture => {
      if (picture['src'] == this.focusedImage.imageUrl) {
        let pRect = picture.getBoundingClientRect();
        let cRect = container.getBoundingClientRect()
        container.scrollTo({
          top: 0,
          left: container.scrollLeft + ((pRect.x + (pRect.width)/2) - (cRect.width/2)),
          behavior: 'smooth'
        });
      }
      
    });
    
  }

  onImageSelect(image) {
    this.imageChanged.emit(image);
    let wait = setInterval(() => {
      document.querySelector('.image-list').scrollIntoView(false);
      clearInterval(wait);
    }, 202);
  }

}
