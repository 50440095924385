<div class="center-children" >
  <app-nav-bar (selectedNav)="updateContent($event)"></app-nav-bar>
  <h3 class="briana">briana ihasz</h3>
</div>

<div class="spacer"></div>

<div *ngIf="navString == 'portfolio'">
  <app-gallery></app-gallery>
</div>

<div *ngIf="navString == 'commission'">
  <app-commission></app-commission>
</div>

<div *ngIf="navString == 'about'">
  <app-about></app-about>
</div>

<div *ngIf="navString == 'contact'">
  <app-contact></app-contact>
</div>

