<div class="com-container">
    <div class="com-box">
        <span class="com-title nav-font">Pricing</span>
        <hr style="width: 33%;">
    
        <div class="card com-text">
            <div class="card-header" style="background-color: #CCFCF1;">
                <span>BASIC Watercolor Pricing Structure</span>
            </div>
            <div class="card-body">
                <ul>
                    <li>Portraiture and Other $0.40 per sq in , Ex. 8x10 = $32</li>
                    <li>Families $0.40 per sq in + $4 per person</li>
                    <li>Ex. 11x14 of 3 people = $73.60</li>
                </ul>
            </div>
        </div>
        <div class="card mt-3 ">
            <div class="card-header" style="background-color: #CCFCF1;">
                <span>BASIC Graphite Pricing Structure</span>
            </div>
            <div class="card-body">
                <ul>
                    <li>Portraiture and Other $0.25 per sq in, Ex.  8x10 = $20</li>
                    <li>Families $0.40 per sq in + $4 per person</li>
                    <li>Ex. 11x14 of 3 people = $50.50</li>
                </ul>
            </div>
        </div>
        <div class="card mt-3 textWarn">
            <div class="card-body">
                <span>Basic Pricing is contingent upon the receival of photo references.
                    Custom designs will need to be quoted individually.
                </span>
            </div>
            
        </div>
    </div>
</div>

