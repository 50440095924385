<div class="spacer"></div>
<div class="image-list">
    <div *ngFor=" let image of imageList">
        <div *ngIf="image == focusedImage; else notFocused">
            <img src={{image.imageUrl}} class="gallery-image selected" name="image.displayName">
        </div>
        <ng-template #notFocused>
            <img src={{image.imageUrl}} class="gallery-image" (click)="onImageSelect(image)" name="image.displayName">
        </ng-template>
       
    </div>
</div>
